.link-list{
    list-style: none;
    padding-left: 0;
}

.link-list li{
    position: relative;
    margin-left: 0;
    padding-left: calc(25rem / 16);
}

.link-list a:before{
    font-family: iconfont;
    content: var(--icon-arrow_big-right);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
}

/*.link-list a:hover:before,*/
/*.link-list a:active:before,*/
/*.link-list a:focus:before{*/
/*    left: 8px;*/
/*}*/


.check-list{
    list-style: none;
    padding-left: calc(20rem / 16);
}

.check-list li{
    position: relative;
    margin-left: 0;
    padding-left: calc(23rem / 16);
}

.check-list li:before{
    color: var(--color-primary);
    font-family: iconfont;
    content: var(--icon-checkmark);
    position: absolute;
    top: 6px;
    left: 0;
    bottom: 0;

    font-size: calc(14rem / 16);
}