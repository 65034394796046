.breadcrumb{
    background-color: white;
    padding-left: 0;

    @media screen and (min-width: 768px){
        margin-bottom: calc(88rem / 16);
    }
}

.breadcrumb-item{
    color: var(--color-primary);
}

.breadcrumb-item.active{
    color: #000;
}

.breadcrumb-item+.breadcrumb-item:before{
    font-family: iconfont;
    content: var(--icon-arrow_small-right);
    font-size: calc(9rem / 16);
    padding-right: calc(20rem / 16);

    @media screen and (max-width: 767px) {
        padding-right: calc(4rem / 16);
    }
}

.breadcrumb-item+.breadcrumb-item{
    padding-left: calc(20rem / 16);

    @media screen and (max-width: 767px) {
        padding-left: calc(6rem / 16);
    }
}