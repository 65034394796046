.text-image__btn{
    margin-top: calc(30rem / 16);
}

.text-image__text-container{
    width:100%;
    max-width: 576px;
    padding-top: calc(55rem / 16);
    padding-right: calc(24rem / 16);
    padding-bottom: calc(55rem / 16);

    @media screen and (max-width: 767px){
        padding: calc(24rem / 16);
    }
}
.text-image__text-col-small .text-image__text-container{
    max-width: 697px;
}

.text-image__img{
    box-shadow: 0 10px 15px 0 rgba(43,33,113,0.2);
}

.text-image__text-col{
    @media screen and (min-width: 768px){
        padding-right: 0;
        padding-left: 0;

        flex: 0 0 36.5%;
        max-width: 36.5%;
    }
}
.text-image__text-col-small{
    @media screen and (min-width: 768px){
        padding-right: 0;
        padding-left: 0;

        flex: 0 0 50%;
        max-width: 50%;
    }
}

.text-image__text-col-ml{
    @media screen and (min-width: 768px) {
        margin-left: 6%;
        margin-right: auto;
    }
}
.text-image__text-col-mr{
    @media screen and (min-width: 768px) {
        margin-right: 6%;
        margin-left: auto;
    }
}

.text-image__text-col-small.text-image__text-col-ml{
    @media screen and (min-width: 768px) {
        margin-left: 8%;
        margin-right: auto;
    }
}
.text-image__text-col-small.text-image__text-col-mr{
    @media screen and (min-width: 768px) {
        margin-right: 8%;
        margin-left: auto;
    }

    @media screen and (min-width: 768px) and (max-width: 1419px){
        margin-right: auto;
        margin-left: 8%;
    }
}

.text-image__text-col-mr .text-image__text-container{
    margin-left: auto;
}