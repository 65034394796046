.webcams__headline{
    margin-bottom: calc(40rem / 16);
}

.webcams--shadow{
    box-shadow: 0 10px 20px 0 rgba(43,33,113,.2);
}

.webcams__lightbox-text{
    margin-top: calc(30rem / 16);
    @media screen and (max-width: 767px){
        margin-top: calc(15rem / 16);
        margin-bottom: calc(30rem / 16);
    }
}
.webcams__lightbox-text > a:hover,
.webcams__lightbox-text > a:focus{
    text-decoration: none;
}