html {
    /* Table scaling */
    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: calc(16 / (991 / 100) * 1vw);
    }
}
body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: var(--font-size-default);
    word-break: break-word;

    @media screen and (max-width: 767px){
        font-size: var(--font-size-default-mobile);
    }
}

table{
    word-break: initial;
}
.font-default {
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
}
b,
strong,
.strong {
    font-family: var(--font-default-bold);
    font-weight: normal;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
    color: inherit;
    font-family: var(--font-default-bold);
}

h1, .h1 {
    color: var(--color-primary);
    font-size: calc(80rem/16);
    @media screen and (max-width: 767px){
        font-size: calc(40rem/16);
    }
}
h2, .h2 {
    color: var(--color-primary);
    font-size: calc(58rem/16);
    @media screen and (max-width: 767px){
        font-size: calc(32rem/16);
    }
}
h3, .h3 {
    color: var(--color-primary);
    font-size: calc(32rem/16);
    @media screen and (max-width: 767px){
        font-size: calc(18rem/16);
    }
}
h4, .h4 {
    color: var(--color-primary);
    font-size: calc(22rem/16);
}
h5, .h5 {
    color: var(--color-primary);
    font-size: calc(18rem/16);
}
.bg-primary h6, .bg-primary .h6 {
    color: var(--color-primary);
    font-size: 1em;
}

.bg-primary h1, .bg-primary .h1,
.bg-primary h2, .bg-primary .h2,
.bg-primary h3, .bg-primary .h3,
.bg-primary h4, .bg-primary .h4,
.bg-primary h5, .bg-primary .h5,
.bg-primary h6, .bg-primary .h6{
    color: var(--color-white);
}

.heading-underline:after{
    height: 4px;
    width: calc(98rem / 16);
    background-color: var(--color-primary);
    content: '';
    margin-top: 35px;
    display: block;

    @media screen and (max-width: 767px){
        margin-top: 16px;
        height: 3px;
    }
}

.title-block--content .heading-underline,
.heading-underline--content{
    margin-bottom: calc(60rem / 16);

    @media screen and (max-width: 767px){
        margin-bottom: calc(20rem / 16);
    }
}
.slider-grid-teaser .heading-underline--content{
    margin-top: calc(60rem / 16);

    @media screen and (max-width: 767px){
        margin-top: calc(20rem / 16);
    }
}

.heading-underline--center:after{
    margin-left: auto;
    margin-right: auto;
}

.bg-primary .heading-underline:after,
.heading-underline.text-white:after{
    background-color: var(--color-white);
}

.text-black{
    color: #000 !important;
}

.text-overflow-2{
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.text-overflow-5{
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.link-icon-big{
    font-size: calc(25rem / 16);
}