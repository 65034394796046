.initiative-application__content {
    margin-top: calc(10rem / 16);
}

.initiative-application__link {
    margin-top: calc(10rem / 16);
}

@media screen and (min-width: 768px) {
    .initiative-application__content {
        margin-top: calc(15rem / 16);
    }

    .initiative-application__link {
        margin-top: calc(15rem / 16);
    }
}