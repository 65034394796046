.schedule__day-flight{
    min-height: calc(63rem / 16);
    display: grid;
    min-width:100%;
    font-size: calc(18rem / 16);
    align-items:center;
    grid-gap: 0;
    grid-template-columns: minmax(40px,1fr) minmax(40px,1fr) minmax(40px,1fr) minmax(40px,1fr) minmax(150px,1.5fr) minmax(40px,1fr) minmax(40px,1fr) minmax(40px,1fr) minmax(40px,1fr) minmax(40px,1fr);
    padding: calc(4rem / 16) calc(5rem / 16);
    @media screen and (max-width: 767px){
        grid-template-columns: repeat(auto-fit, minmax(50%,1fr));
    }
}
.schedule__legend{
    font-family: var(--font-default-bold);
    color: var(--color-primary);
    display: grid;
    min-width:100%;
    font-size: calc(18rem / 16);
    align-items:center;
    grid-gap: 0;
    grid-template-columns: minmax(40px,1fr) minmax(40px,1fr) minmax(40px,1fr) minmax(40px,1fr) minmax(150px,1.5fr) minmax(40px,1fr) minmax(40px,1fr) minmax(40px,1fr) minmax(40px,1fr) minmax(40px,1fr);
    padding: calc(4rem / 16) calc(5rem / 16);
    @media screen and (max-width: 767px){
        display:none;
    }
}

.schedule__day-flight,
.schedule__day-flight a{
    color: var(--color-primary);
    transition-duration: 0s;
}
.schedule__day-flight:hover,
.schedule__day-flight:hover a{
    transition-duration: 0s;
    background-color: var(--color-primary);
    color: white;
}

.schedule__day .schedule__day-flight{
    border-bottom: 1px dashed var(--color-primary);
}

.schedules .schedule__day:not(:last-of-type) .schedule__day-flight:last-of-type{
    border-bottom: none;
}

.schedule__day-heading{
    padding-top: calc(20rem / 16);
    padding-bottom: calc(20rem / 16);
}

.load-more-schedules{
    margin-top: calc(40rem / 16);
}

/*.flight-search{*/
/*    padding-top: calc(80rem / 16);*/
/*    padding-bottom: calc(67rem / 16);*/
/*}*/
/*.flight-search__heading{*/
/*    margin-bottom: calc(40rem / 16);*/
/*}*/

.nav-item.flight-table-tab{
    border-radius: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: calc(34rem / 16);

    @media screen and (max-width: 767px){
        padding: calc(10rem / 16) calc(34rem / 16);
    }
}
.nav-item.flight-table-tab *{
    text-decoration: none !important;
}

.flight-table-tab--sticky{
    @media screen and (min-width: 768px){
        top: calc(100rem/16);
    }
    position: sticky;
    top: calc(50rem/16);
    z-index: 1;
}

.flight-table-tab .flight-table-tab__icon{
    @media screen and (min-width: 768px){
        padding-right: calc(22rem / 16);
    }
}

.flight-table-tab.active .flight-table-tab__text,
.flight-table-tab.active .flight-table-tab__icon{
    color: var(--color-primary);
}

.flight-table-tab__text{
    @media screen and (max-width: 767px){
        font-size: calc(11rem / 16);
        text-align: center;
    }
}
.flight-table-tab__text.schedule__underline:after{
    @media screen and (max-width: 767px){
        content: none;
    }
}

.schedule__underline:after{
    display: block;
    content: '';
    margin-top: calc(8rem / 16);
    box-sizing: border-box;
    height: 2px;
    width: 72px;
}
.flight-table-tab.active .schedule__underline:after{
    background-color: var(--color-primary);
}
.flight-table-tab .schedule__underline:after{
    background-color: white;
}

.flight-search__schedule{
    padding-top: calc(50rem/16);

    @media screen and (min-width: 768px) {
        padding-top: calc(100rem/16);
    }
}

.schedules__inquiry {
    color: black;
    padding-bottom: calc(20rem/16);
}