.alert{
    border-radius: 0;
}

.alert-airport-news{
    background-color: #009fe3;
    border-color: #009fe3;

    color: #fff;
    padding: 20px;
    @media screen and (max-width: 767px){
        padding: 10px 0;
    }
}
.alert-airport-news p,
.alert-airport-news ul,
.alert-airport-news ol{
    margin-bottom:8px;
    @media screen and (max-width: 767px){
        margin-bottom:4px;
    }
}

.alert-airport-news__link{
    color: #fff;
}
.alert-airport-news__link>.icon{
    width: 20px;
    height: 20px;
    border-radius: 50%;

    background-color: var(--color-white);
    color: #009fe3;
    font-size: 20px;

    position: relative;
    text-align: center;
    margin-left: 10px;
    top:2px;
}

.alert-airport-news__info-icon{
    border: 5px solid var(--color-white);
    border-radius: 50%;
    width: 50px;
    height: 50px;
}
.alert-airport-news__info-icon .portal-hero-table__additional-info-icon{
    font-size: 35px;
    margin-right: 5px;
}

.alert-dismissible .close{
    @media screen and (max-width: 767px){
        padding: .25rem .625rem;
    }
}
.alert-dismissible .close:hover,
.alert-dismissible .close:focus,
.alert-dismissible .close:active{
    color: var(--color-white);
}