.jump-nav {
    padding: 0;
}
.jump-nav .nav-link{
    opacity: .5;
    padding: 1rem;
}

.jump-nav .nav-link:hover,
.jump-nav .nav-link:focus,
.jump-nav .nav-link.active{
    opacity: 1;
}

.jump-nav__affix{
    z-index: 2;
}

.nav-offset{
    padding-top: 160px;
    margin-top: -160px;
}