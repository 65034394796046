.search-result{
    border-bottom: 1px solid #dee2e6;
    cursor: pointer;

    padding: calc(5rem / 16);
    padding-top: calc(21rem / 16);
}

.search-result:hover,
.search-result:active,
.search-result:focus{
    background-color: var(--color-dark);
}


.search-result__search-input{
    border: 1px solid var(--color-primary);
    padding: calc(6rem / 16) calc(12rem / 16);
    height: calc(53rem / 16);
    width: calc(500rem / 16);

    @media screen and (max-width: 767px){
        height: calc(50rem / 16);
    }
}