@font-face {
    font-family: 'calibre-light';
    src: url('/static/fonts/CalibreWeb-Light.woff2') format('woff2'),
    url('/static/fonts/CalibreWeb-Light.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'calibre-medium';
    src: url('/static/fonts/CalibreWeb-Medium.woff2') format('woff2'),
    url('/static/fonts/CalibreWeb-Medium.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'calibre-regular';
    src: url('/static/fonts/CalibreWeb-Regular.woff2') format('woff2'),
    url('/static/fonts/CalibreWeb-Regular.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'calibre-thin';
    src: url('/static/fonts/CalibreWeb-Thin.woff2') format('woff2'),
    url('/static/fonts/CalibreWeb-Thin.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'calibre-semibold';
    src: url('/static/fonts/CalibreWeb-semibold.woff2') format('woff2'),
    url('/static/fonts/CalibreWeb-semibold.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}