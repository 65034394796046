.blog-user__icon{
    width: 80px;
    min-width: 80px;
    height: 80px;

    border-radius: 50%;
    margin-right: calc(19rem / 16);
    border: 3px solid #FFFFFF;
}
.blog-user__icon-big{
    width: 110px;
    min-width: 110px;
    height: 110px;

    border-radius: 50%;
    margin-right: calc(27rem / 16);
    border: 3px solid #FFFFFF;
}

.blog-big-text{
    padding: calc(80rem / 16) calc(130rem / 16);

    @media screen and (min-width: 768px) and (max-width: 1199px){
        padding: calc(40rem / 16) calc(65rem / 16);
    }
    @media screen and (max-width: 767px){
        padding: calc(40rem / 16) calc(15rem / 16);
    }
}

.blog-big-text__paragraph{
    margin-top: calc(60rem / 16);

    @media screen and (max-width: 1199px){
        margin-top: calc(30rem / 16);
    }
}

.blog-big-text__paragraph-heading{
    margin-bottom: calc(20rem / 16);
}

.blog-big-text__paragraph-btn{
    margin-top: calc(40rem / 16);

    @media screen and (max-width: 1199px){
        margin-top: calc(20rem / 16);
    }
}

.blog-teaser__small{
    box-shadow: 0 10px 20px 0 rgba(43,33,113,0.1);
    margin-bottom: calc(32rem / 16);
    cursor: pointer;
}
.blog-teaser__small:hover,
.blog-teaser__small:focus,
.blog-teaser__small:active{
    box-shadow: 0 10px 10px 0 rgba(43,33,113,.2);
}

.blog-teaser__big{
    margin-bottom: calc(24rem / 16);
}

.blog-teaser__small-user{
    transform: translateY(-16px);
    padding-left: calc(20rem / 16);
}

.blog-teaser__small-user .blog-user__info{
    transform: translateY(16px);
}

.blog-teaser__small-text{
    top: calc(28rem / 16);
    left: calc(30rem / 16);
    right: calc(30rem / 16);
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.9);

    @media screen and (min-width: 768px) and (max-width: 991px){
        top: calc(14rem / 16);
        left: calc(15rem / 16);
        right: calc(15rem / 16);
    }
}

.blog-citation{
    padding-top: calc(148rem / 16);
    padding-bottom: calc(116rem / 16);
}

.blog-citation__underline:after{
    content: '';
    height: 3px;
    display: block;
    width: calc(98rem / 16);
    background-color: var(--color-primary);
    margin: calc(50rem / 16) auto calc(30rem / 16) auto;
}

.blog-citation__text-width{
    padding: 0 calc(96rem / 16) calc(53rem / 16) calc(96rem / 16);
    @media screen and (max-width: 767px){
        padding: 0 calc(20rem / 16) calc(53rem / 16) calc(20rem / 16);
    }
}

.blog-citation__user{
    width: calc(109rem / 16);
    min-width: calc(109rem / 16);
    height: calc(109rem / 16);
    border-radius: 50%;
    border: 3px solid #fff;
    margin: 0 auto;
    transform: translateY(-50%);
}

.blog-citation__icon{
    position: relative;
}

.blog-citation__icon:before{
    position: absolute;
    top: calc(-20rem / 16);
    left: calc(-28rem / 16);
    font-family: iconfont;
    content: var(--icon-quotation-mark);
    color: var(--color-light-grey);
    font-size: calc(82rem / 16);

    @media screen and (max-width: 767px){
        left: calc(-10rem / 16);
    }
}

.blog-link-btn{
    margin: 0 calc(10rem / 16);
}



/*pagination*/
.blog-pagination .pagination{
    margin-top: calc(15rem / 16);
    margin-bottom: calc(15rem / 16);
    font-family: var(--font-default-bold);
}
.blog-pagination .page-item .page-link{
    border: 0;
    font-size: calc(32rem / 16);
    color: var(--color-light-grey);
    line-height: 1;

    @media screen and (max-width: 767px){
        font-size: calc(20rem / 16);
    }
}
.blog-pagination .page-item.disabled .page-link {
    background-color: transparent;
}
.blog-pagination .page-item .page-link:hover,
.blog-pagination .page-item .page-link:active,
.blog-pagination .page-item .page-link:focus{
    background-color: transparent;
    color: var(--color-primary);
}

.blog-pagination .page-item.active .page-link{
    background-color: initial;
    color: var(--color-primary);
    border: 0;
}
.blog-pagination .page-item.active{
    position: relative;
}
.blog-pagination .page-item.active:after{
    content: "";
    display: block;
    height: 3px;
    width: calc(20rem / 16);
    position: absolute;
    background-color: var(--color-primary);
    left: 0;
    right: 0;
    bottom: 10px;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 767px){
        width: calc(15rem / 16);
        height: 2px;
    }
}
.blog-pagination .page-item.page-item__arrow .page-link {
    padding: 0;
    font-size: 1.5rem;
    width: calc(70rem / 16);
    height: calc(70rem / 16);
    color: var(--color-primary);

    @media screen and (max-width: 767px){
        width: calc(40rem / 16);
        height: calc(40rem / 16);
        font-size: 1rem; 
    }
}
.page-item__arrow .page-link{
    padding: 0;
    width: 100%;
    height: 100%;
    margin: 0;

    border-radius: 50%;
    box-shadow: 0 10px 15px 0 rgba(43,33,113,0.2);

    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 767px){
        border-radius: 35px;
    }
}

.blog-detail-hero__text-container{
    padding-top: calc(40rem / 16);
    padding-bottom: calc(80rem / 16);
    @media screen and (max-width: 767px){
        padding-top: calc(30rem / 16);
        padding-bottom: calc(50rem / 16);
    }
}
.blog-detail-hero__headlines{
    margin-top: calc(80rem / 16);
    margin-bottom: calc(80rem / 16);
    @media screen and (max-width: 767px){
        margin-top: calc(40rem / 16);
        margin-bottom: calc(40rem / 16);
    }
}
.blog-detail-hero__img-container{
    @media screen and (min-width: 768px){
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}