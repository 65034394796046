.form-upload{
    padding: calc(9rem / 16);
}

.form-upload__icon{
    font-size: calc(24rem / 16);
}

.contact-form__heading{
    margin-bottom: calc(54rem / 16);
}

.form-spacing{
    padding-top: calc(74rem / 16);
    padding-bottom: calc(105rem / 16);
}

.form-group.has-value .floating-label{
    transform: translateY(-.5rem) scale(.6);
}

.floating-label{
    left: 1.75rem;
    top: calc(13rem / 16);
}
.text-area .floating-label{
    top: calc(7rem / 16);
}

.file-input__delete{
    position: absolute;
    top: 0;
    right: calc(13rem / 16);
    bottom: 0;
}
.floating-label.file-upload__label{
    left: calc(58rem / 16);
}

.file-input__text{
    padding-bottom: 0;
    padding-top: calc(12rem / 16);
}

.required-info{
    font-size: calc(12rem / 16);
    margin-bottom: calc(50rem / 16);
}

.hasDatepicker{
    cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type=number] {
    -moz-appearance:textfield;
}