.arrow-link {
    color: var(--color-primary);
    font-size: calc(15rem / 16);
    font-weight: bold;
    line-height: calc(19.2rem / 16);
}

.arrow-link:hover .arrow-link__icon {
    transform: translateX(20%);
}

.arrow-link__icon {
    margin-right: calc(5rem / 16);
    font-size: calc(18rem / 16);
    transition: transform 0.2s ease-in-out;
}

@media screen and (min-width: 768px) {
    .arrow-link {
        font-size: calc(18rem / 16);
        line-height: calc(25rem / 16);
    }

    .arrow-link__icon {
        font-size: calc(25rem / 16);
    }
}