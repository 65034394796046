.job-teaser-list {
    margin-top: calc(17rem/16);
    
    @media screen and (min-width: 768px) {
        margin-top: calc(60rem/16);
    }
}

.job-teaser-list__item:last-child > .job-teaser {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
}

.job-teaser-list__item > .job-teaser {
    border-bottom: calc(1rem/16) solid var(--color-light-grey);
    padding-bottom: calc(17rem/16);
    margin-bottom: calc(17rem/16);
}

.job-teaser__title {
    font-size: calc(18rem/16);
    line-height: calc(22rem/16);
    font-family: var(--font-default-medium);

    @media screen and (min-width: 768px) {
        font-size: calc(32rem/16);
        line-height: calc(35rem/16);
    }
}

.job-teaser__two-infos {
    display: flex;
}

.job-teaser__location-info {
    margin-right: calc(20rem/16);

    @media screen and (min-width: 768px) {
        margin-right: calc(32rem/16);
    }
}

.job-teaser__info-wrapper {
    margin-top: calc(10rem/16);
    margin-bottom: calc(10rem/16);
    font-size: calc(15rem/16);
    line-height: calc(23rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(15rem/16);
        margin-bottom: calc(20rem/16);
        font-size: calc(18rem/16);
        line-height: calc(26rem/16);
    }
}

.job-teaser__links {
    display: flex;
}

.job-teaser__enquery-link {
    margin-right: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        margin-right: calc(20.5rem/16);
    }
}