.flight-plan{
    padding-top: calc(80rem / 16);
    padding-bottom: calc(67rem / 16);

    @media screen and (max-width: 767px){
        padding-top: calc(30rem / 16);
        padding-bottom: calc(30rem / 16);
    }
}

.flight-plan .form-control{
    font-size: calc(18rem / 16);
    color: var(--color-primary);
}

.datepicker-icon{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: calc(12rem / 16);

    font-size: calc(30rem / 16);
    pointer-events: none;
}

.floating-label__datepicker{
    left: calc(12rem / 16);
}