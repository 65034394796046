.thead-primary td{
    background-color: var(--color-primary);
    color: white;
}

.extern-partners{
    box-shadow: 0 10px 20px 0 rgba(43,33,113,0.1);
    margin-bottom: calc(15rem / 16);


    padding: calc(20rem / 16) calc(40rem / 16);
}