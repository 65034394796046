.parkrechner{
    margin: calc(88rem / 16) 0 calc(78rem / 16) 0;
    font-size: calc(18rem / 16);

    @media screen and (max-width: 767px){
        margin: calc(40rem / 16) 0 calc(40rem / 16) 0;
    }
}
.parkrechner .form-control{
    font-size: calc(18rem / 16);
}
.parkrechner .floating-label{
    left: 0.75rem;
}

.parkrechner__subtitle{
    margin: calc(33rem / 16) 0;
    @media screen and (max-width: 767px){
        margin: calc(15rem / 16) 0;
    }
}

.calculated_sum{
    line-height: .7;
}

.calculation-spacer{
    margin: calc(49rem / 16) 0;
    @media screen and (max-width: 767px){
        margin: calc(15rem / 16) 0;
    }
}

.parking-downloads{
    margin-top: calc(60rem / 16);
    @media screen and (max-width: 767px){
        margin-top: calc(15rem / 16);
    }
}

.parkrechner-background{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.parkrechner-results{
    @media screen and (max-width: 767px){
        margin-top: calc(20rem / 16);
        margin-bottom: calc(20rem / 16);
    }
}

.parkrechner-date-icon{
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: .5rem;
    transform: translateY(-50%);
    font-size: calc(30rem / 16);

    pointer-events: none;
}

.parkrechner-date-icon--agancy{
    right: 1rem;
    margin-top: 0;
    top:.5rem; 
    transform: translateY(0);
}

.form-control--time{
    min-width: 50px;
    max-width: 50px;
    height: 49px;
    padding: 2px;
    text-align: center;
}

.parkrechner-width{
    /*fit-content only works in chrome and firefox. Display table does the same in this case*/
    /*width: fit-content;*/
    display: table;
}

.get-costs{
    margin-top: calc(14rem / 16);
}