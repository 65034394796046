.accordion__header, .accordion__header:first-child {
    border-radius: 0;
}

.accordion__header-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.accordion__header-link.collapsed .accordion__header-icon {
    transform: rotate(0);
}

.accordion__header-icon {
    font-size: calc(8rem/16);
    transform: rotate(180deg);
    transition: transform 0.3s ease;
    @media screen and (max-width: 767px) {
        font-size: calc(6rem/16);
    }
}