.main-nav{
    height: 100px;

    @media screen and (max-width: 767px){
        height: 50px;
        padding:0;
    }
}

.main-nav__sub-nav{
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    @media screen and (max-width: 767px){
        padding-top: calc(51rem / 16);
    }
    @media screen and (min-width: 768px){
        transform: translateY(100%);
    }
    @media screen and (min-width: 768px) and (max-width: 991px){
        bottom: calc(7rem / 16);
    }
/*background-color: white;*/
/*z-index: 1;*/
}
.main-nav__sub-nav .container{
    background-color: white;
}

.main-nav__sub-nav.main-nav__sub-nav--small{
    left: calc(-63rem / 16);
}

.main-nav__sub-nav.is-open{
    display: block;
    z-index: 101;

}

.main-nav__sub-nav.is-open .container,
.main-nav__nav-item--has-subnav.is-open .container{
    @media screen and (min-width: 768px){
        box-shadow: 0 0 60px 0 rgba(43,33,113,0.3);
        border-radius: 3px;
    }
}

.dropdown-menu{
    border-radius: 3px;
}
.main-nav__lang-btn + .dropdown-menu .dropdown-item:hover,
.main-nav__lang-btn + .dropdown-menu .dropdown-item:active{
    background-color: var(--color-primary);
    color: white;
}

.main-nav__brand-img{
    height: calc(50rem / 16);
    width: auto;
    margin-left: calc(14rem / 16);

    @media screen and (max-width: 767px){
        height: calc(35rem / 16);
        margin-left: calc(10rem / 16);
    }
}

.main-nav__nav{
    @media screen and (min-width: 768px){
        height: 100%;
    }
    @media screen and (max-width: 767px){
        width: 100%;
        padding: 0 calc(36rem/16);
    }
}

.main-nav__nav-item{
    display: flex;
    align-items: center;

    @media screen and (max-width: 767px){
        padding-right: 0 !important;
    }
}

.main-nav__nav-item--has-subnav:hover > .main-nav__nav-link:after,
.main-nav__nav-item--has-subnav:focus > .main-nav__nav-link:after{
    @media screen and (min-width: 768px){
        content: '';
        display: block;

        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 20px 10px;
        border-color: transparent transparent #ffffff transparent;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 35px);
    }
}

/*.header-fixed .main-nav__nav-item:hover > .main-nav__nav-link:after,*/
/*.header-fixed .main-nav__nav-item:focus > .main-nav__nav-link:after,*/
/*.header-fixed .main-nav__nav-link:focus:after,*/
/*.header-fixed .main-nav__nav-link:active:after{*/
/*    @media screen and (min-width: 768px){*/
/*        background-color: var(--color-secondary);*/
/*    }*/
/*}*/

.main-nav__nav-item:not(:last-of-type){
    padding-right: calc(58rem / 16);
    @media screen and (max-width: 1199px){
        padding-right: calc(20rem / 16);
    }
}
.main-nav__nav-link{
    position: relative;
    z-index: 102;
    color: var(--color-primary);
    font-size: calc(20rem / 16);
    font-family: var(--font-default-bold);
    white-space: nowrap;

    @media screen and (max-width: 767px){
        font-size: calc(18rem / 16);
        color: #000;
        margin-bottom: calc(30rem/16);

        width: 100%;
        display: flex;
        align-items: center;
    }
}


.main-nav__nav-link .icon{
    @media screen and (max-width: 767px){
        font-size: calc(12rem / 16);
        margin-left:.5rem;
    }
}

.main-nav__collapse{
    justify-content: center;
    height: 100%;
}

.main-nav__icons{
    color: var(--color-primary);
    display: flex;
}

.main-nav__icon{
    font-size: calc(16rem / 16);
    padding: 0;
    margin-right: calc(30rem / 16);
    font-family: var(--font-default-bold);
    white-space: nowrap;

    @media screen and (max-width: 1400px){
        margin-right: calc(15rem / 16);
    }
}
.main-nav__icon:hover div{
    text-decoration: underline;
}
.main-nav__icons .main-nav__icon{
    @media screen and (max-width: 1199px){
        margin-right: calc(15rem / 16);
    }
    margin-right: calc(30rem / 16);
}

.main-nav__icon .icon{
    font-size: calc(28rem / 16);
    margin-bottom: calc(8rem / 16);
}

.navbar-brand{
    @media screen and (max-width: 1200px){
        margin-right: 0;
    }
}

.main-nav__sub-nav--small{
    @media screen and (min-width: 768px){
        width: auto;
        right:auto;
    }
}

.main-nav__sub-nav-heading{
    @media screen and (max-width: 767px){
        padding: calc(32rem / 16) 0 calc(20rem / 16);
    }
}
.main-nav__subsub-nav .main-nav__sub-nav-heading{
    @media screen and (max-width: 767px){
        padding: calc(32rem / 16) 0 calc(30rem / 16);
    }
}

.main-nav__sub-nav__spacing{
    padding: calc(55rem / 16) calc(100rem / 16);
    @media screen and (max-width: 767px){
        padding: calc(0rem / 16) calc(20rem / 16) calc(19rem / 16);
    }
}
.main-nav__subsub-nav .main-nav__sub-nav__spacing{
    padding: calc(55rem / 16) calc(100rem / 16);
    @media screen and (max-width: 767px){
        padding: calc(20rem / 16) 0 calc(19rem / 16);
    }
}
.main-nav__sub-nav--small .main-nav__sub-nav__spacing{
    @media screen and (min-width: 768px){
        padding: calc(55rem / 16) calc(60rem / 16);
    }
}

.main-nav__collapse{
    @media screen and (max-width: 767px){
        display: none;
    }
}

.is-open .main-nav__collapse {
    @media screen and (max-width: 767px){
        padding-top: calc(83rem/16);
        position:fixed;
        top: 50px;
        left: 0;
        right: 0;
        height: calc(100% - 50px);
        display: flex;
        flex-direction: column;
        background-color: white;
        z-index: 2;

        justify-content: space-between;
    }
}

.nav-item.is-open>.main-nav__sub-nav .container{
    background-color: white;
}

.nav-item.is-open>.main-nav__sub-nav{
    display: block;
    z-index: 103 !important;

    @media screen and (max-width: 767px){
        position:fixed;
        top: 50px;
        left: 0;
        right: 0;
        height: calc(100% - 50px);
        display: flex;
        flex-direction: column;
        background-color: white;
        z-index: 1;

        overflow: scroll;
    }
}

.main-nav__subsub-nav{
    @media screen and (max-width: 767px){
        display:none;
    }
}

.is-open>.main-nav__subsub-nav{
    @media screen and (max-width: 767px){
        display: block;
        position: fixed;
        height: calc(100% - 50px);
        top: 50px;
        left: 0;
        right: 0;
        padding: calc(51rem / 16) calc(36rem/16) 0;
        background-color: white;
        z-index: 103;
        
        //padding-top: calc(51rem / 16);
    }
}

.main-nav__back-btn{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    border-radius: 0;
}

.main-nav__nav-item-arrow{
    @media screen and (max-width: 767px){
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }
}

.main-nav__nav-item-arrow .icon{
    @media screen and (max-width: 767px){
        margin-bottom: calc(30rem/16);
    }
}

.main-nav__breadcrumb{
    font-size: calc(24rem / 16);
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    margin-bottom:2rem;
    @media screen and (max-width: 767px){
        font-size: calc(18rem / 16);
        margin-bottom:0;
    }
}

.main-nav__breadcrumb:after{
    content: "";
    width: 2.875rem;
    display: block;
    height: 3px;
    background-color: var(--color-primary);
    margin: .625rem 0;
    @media screen and (max-width: 767px){
        width: 1.625rem;
        height: .0625rem;
        margin: .625rem 0;
    }
}


.main-nav__sub-nav-item{
    margin-bottom: calc(8rem / 16);
    @media screen and (max-width: 767px){
        margin-bottom: calc(25rem / 16);
    }
}
.main-nav__sub-nav-item-link{
    @media screen and (max-width: 767px){
        font-size: calc(18rem / 16);
        font-family: var(--font-default-bold);
    }
    @media screen and (min-width: 768px){
        white-space: nowrap;
    }
}

.main-nav__sub-nav-item-link:hover,
.main-nav__sub-nav-item-link:active{
    color: var(--color-primary);
}

.main-nav__toggler{
    background-color: var(--color-primary);
    border-radius: 0;
    color: white;
}
.main-nav__toggler.main-nav__toggler--mobile .main-nav__toggler__heading{
    font-size: calc(15rem / 16);
}
.header-fixed .navbar:not(.is-affix) .main-nav__toggler{
    background-color: var(--color-secondary);
    color: var(--color-primary);
}

.main-nav__toggler .icon-menu{
    display: block;
}
.is-open .main-nav__toggler .icon-menu{
    display: none;
}

.main-nav__toggler .icon-close{
    display: none;
}
.is-open .main-nav__toggler .icon-close{
    display: block;
}


.header-fixed{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 11;
}

    @media screen and (min-width: 768px){
        .header-fixed .navbar:not(.is-affix) .main-nav__nav-link:not(.main-nav__sub-nav-title){
            color: white;
        }
        .header-fixed .navbar:not(.is-affix) .main-nav__nav-link:not(.main-nav__sub-nav-title):hover,
        .header-fixed .navbar:not(.is-affix) .main-nav__nav-link:not(.main-nav__sub-nav-title):focus{
            text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.9);
        }
    }
/*.header-fixed .navbar:not(.is-affix) .main-nav__nav-link.main-nav__sub-nav-title{
@media screen and (min-width: 768px){
    color: initial;
}
}*/

.btn.main-nav__search-btn{
    @media screen and (max-width: 767px) {
        font-size: calc(23rem / 16);
        margin-right: calc(20rem / 16);
    }
}

.header-fixed .navbar:not(.is-affix) .main-nav__icons,
.header-fixed .navbar:not(.is-affix) .main-nav__search-btn{
    color: white;
}

.header-fixed .navbar{
    transition-duration: .5s;
}

.navbar.is-affix{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 3;

    @media screen and (min-width: 1920px){
        max-width: 1920px;
        margin:auto;
    }
}

.navbar.is-affix{
    transition-duration: .75s;
    box-shadow: 0 0 10px 0 rgba(43,33,113,.1);
}

.header-fixed .navbar .navbar-brand__fixed{
    display: none;
}
.header-fixed .navbar.is-affix .navbar-brand__fixed{
    display: block;
}

.header-fixed .navbar .navbar-brand__not-fixed{
    display: block;
}
.header-fixed .navbar.is-affix .navbar-brand__not-fixed{
    display: none;
}

.navbar-top__search__input{
    border: 1px solid var(--color-primary);
    height: calc(50rem / 16);
    padding: calc(6rem / 16) calc(12rem / 16);
}

#searchModal .modal{
    background-color: var(--color-primary);
}

.main-nav__sub-nav-title-wrapper{
    @media screen and (min-width: 768px){
        margin-bottom: calc(15rem / 16);
    }
}

.nav-wrapper{
    @media screen and (min-width: 1920px){
        max-width: 1920px;
        margin: auto;
    }
}

.main-nav__icons.main-nav__icons-mobile{
    @media screen and (max-width: 767px){
        color: var(--color-primary) !important;
    }
}
.main-nav__icons.main-nav__icons-mobile .btn{
    border-radius: 0;
}

.main-nav .nav__container{
    @media screen and (max-width: 767px){
        pointer-events: none;
    }
}
.main-nav.is-open .nav__container{
    pointer-events: initial;
}