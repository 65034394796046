.arrival__btn{
    margin-top: calc(30rem / 16);
    margin-bottom: calc(80rem / 16);
}

.arrival-search__input-icon{
    position: absolute;
    left: calc(17rem / 16);
    top: calc(15rem / 16);
    color: var(--color-primary);
    z-index: 1;
}

.arrival-search__label{
    left: calc(45rem / 16);
    top: calc(10rem / 16);
}

.form-control.arrival-search__input{
    padding-left: calc(45rem / 16);
}

.arrival__container{
    max-width: calc(456rem / 16);
    margin-left: auto;

    @media screen and (max-width: 767px){
        margin-right: auto;
        padding-left: calc(16rem / 16);
        padding-right: calc(16rem / 16);
    }
    @media screen and (min-width: 768px) and (max-width: 1199px){
        width: calc(300rem / 16);
    }
    @media screen and (min-width: 1200px){
        width: calc(456rem / 16);
    }
}

.arrival-link{
    margin-bottom: calc(13rem / 16);
}
.arrival-link__icon{
    font-size: calc(25rem / 16);
    padding-right: 5px;
}

.arrival__container-text{
    max-width: calc(335rem / 16);
    margin-right: auto;

    @media screen and (max-width: 767px){
        margin-left: auto;
        width: 100%;
    }
    @media screen and (min-width: 768px){
        padding-right: calc(20rem/16);
    }
    @media screen and (min-width: 1200px){
        width: calc(335rem / 16);
    }

    @media screen and (min-width: 1200px) and (max-width: 1919px){
        margin-left: auto;
        margin-right: 0;

        padding-left: calc(20rem/16);
    }
}

.arrival__img{
    max-width: 1214px;
    /*margin-left: calc(20rem / 16);*/

    @media screen and (min-width: 1200px) and (max-width: 1919px){
        max-width: 65%;
    }
}