.press-images{
    margin-top: calc(16rem / 16);
    margin-bottom: calc(16rem / 16);
    position: relative;
}

.press-images__big:after{
    pointer-events: none;
    content: "";
    position: absolute;
    width: 100%;
    height: 50%;
    top: 0;
    transition-duration: .25s;
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,0) 44%, rgba(255,255,255,0.33657212885154064) 73%, rgba(255,255,255,0.6446953781512605) 100%);
}

.press-images__big:hover:after{
    z-index: 1;
}

.press-images:hover{
    z-index: 1;
}

.press-images .press-images__big img{
    pointer-events: none;
    -webkit-touch-callout: none;
}

img.lg-image {
    pointer-events: none;
    -webkit-touch-callout: none;
}

.press-images .press-images__big{
    transition-duration: .25s;
    @media screen and (max-width: 991px){
        box-shadow: 0 15px 30px 0 rgba(0,0,0,0.3);
    }
}
.press-images:hover .press-images__big{
    transform: scale(1.25);
    transition-duration: .25s;
    z-index: 1;

    @media screen and (max-width: 1199px){
        transform: scale(1.05);
    }

    box-shadow: 0 15px 30px 0 rgba(0,0,0,0.3);
}

.press-images__download{
    padding: calc(12rem / 16);
    background-color: white;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    display: none;
    @media (pointer: coarse) {
        display: block;
    }
}
.press-images:hover .press-images__download,
.lg-sub-html .press-images__download{
    display: block;
}
.lg-sub-html .press-images__download{
    color: var(--color-primary);
}

.lightbox__item:hover{
    text-decoration: none;
}

.lightbox-icon{
    color: var(--color-primary);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition-duration: .25s
}
.lightbox__location{
    position: absolute;
    top: calc(12rem/16);
    left: calc(12rem/16);
    z-index: 1;
    transition-duration: .25s;
    color: var(--color-primary);
}
.press-images:hover .lightbox__location{
    z-index: 5;
    transition-duration: .25s;
}
.press-images:hover .lightbox-icon{
    opacity: 1;
    transition-duration: .25s;
    text-decoration: none !important;
}

.lightbox-icon__circle{
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: white;
    text-decoration: none !important;

    @media screen and (max-width: 1199px){
        width: 60px;
        height: 60px;
    }
}

.press-images__archive{
    margin-top: calc(32rem / 16);
}

.press-text-teaser{
    cursor: pointer;
    box-shadow: 0 10px 20px 0 rgba(43,33,113,.1);
    border-radius: 3px;
}

.press-text-teaser:hover{
    cursor: pointer;
    box-shadow: 0 10px 10px 0 rgba(43,33,113,.2);
}
