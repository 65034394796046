.btn{
    padding: calc(16rem / 16) calc(22rem / 16);
    line-height: 1;
    font-size: var(--font-size-default: calc(18rem / 16));
    -webkit-appearance: none;
    @media screen and (max-width: 767px){
        font-size: var(--font-size-default-mobile: calc(15rem / 16));
    }
    font-family: var(--font-default-bold);
}

.btn.btn-lg{
    padding: calc(16rem / 16) calc(42rem / 16);
    border-radius: calc(100rem / 16);
}

.btn-white{
    background-color: var(--color-white);
}

.btn.btn-primary{
    border-color: var(--color-secondary);
    color: var(--color-white);
}

.btn.btn-secondary{
    border-color: var(--color-primary);
    color: var(--color-primary);
}

.btn.btn-secondary:hover,
.btn.btn-secondary:active,
.btn.btn-secondary:not(:disabled):not(.disabled):active{
    background-color: var(--color-primary);
    border-color: var(--color-secondary);
    color: white;
}

.btn-link .icon{
    font-size: calc(20rem / 16);
}

.btn-icon-text{
    /*TODO: add magic here for icon hover*/
    display: inline-flex;
    justify-content: center;
}

.btn.btn-unstyled{
    border: none;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    color: var(--color-primary);
    text-decoration: none;
}
.btn.btn-unstyled:hover,
.btn.btn-unstyled:focus{
    text-decoration: none;
}
a.btn:hover span,
a.btn:active span,
a.btn:focus span{
    text-decoration: none !important;
}
