.slider-gallery{
    position: relative;
}

.slider-gallery .slick-slide.slick-center{
    transform: scale(1);
    opacity: 1;
}
.slider-gallery .slick-slide.slick-center .embed-responsive{
    margin: calc(10rem / 16) 0;
    box-shadow: 0 0 15px 0 rgba(0,0,0,.5);
}

.slider-gallery .slick-slide {
    transition-duration: .5s;
    transform: scale(.45);
    transform-origin: 100% 50%;
    position: relative;
    opacity: .5;
}

.slider-gallery .embed-responsive{
    overflow: visible;
}
.slider-gallery .slick-slide .embed-responsive:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition-duration: .33s;
    transition-property: opacity;

    opacity: 0.5;
}
.slider-gallery .slick-slide.slick-center .embed-responsive:after{
    opacity: 1;
    z-index: -1;
}

.slider-gallery .slick-slide.slick-center + .slick-slide{
    transform-origin: 0 50%;
}

.slider-gallery--prev,
.slider-gallery--next{
    width: 70px;
    height: 70px;
    background-color: #fff;
    border: 0;
    z-index: 1;
    border-radius: 50%;
    box-shadow: 0 10px 15px 0 rgba(43,33,113,0.2);

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.slider-gallery--prev{
    left: 0;

    transform: translateY(-50%) translateX(-50%);
    @media screen and (max-width: 991px) {
        transform: translateY(-50%) translateX(0);
    }
}
.slider-gallery--next{
    right: 0;
    transform: translateY(-50%) translateX(50%);
    @media screen and (max-width: 991px) {
        transform: translateY(-50%) translateX(0);
    }
}

.slider-gallery.slick-disabled{
    display: none !important;
}

.slider-counter{

    @media screen and (max-width: 767px){
        text-align: center;
    }
    @media screen and (min-width: 768px) and (max-width: 991px){
        position: absolute;
        bottom: calc(50rem / 16);
        right: calc(45rem / 16);
    }
    @media screen and (min-width: 992px){
        position: absolute;
        bottom: calc(100rem / 16);
        right: calc(45rem / 16);
    }
}