.portal-hero{
    @media screen and (min-width: 1420px){
        padding-bottom: calc(30rem / 16);
    }
}
.portal-hero--quick-search {
    @media screen and (min-width: 1420px){
        padding-bottom: calc(430rem / 16);
    }
}
.portal-hero__iframe {
    height: calc(350rem / 16);

    @media screen and (min-width: 1200px) {
        height: calc(650rem / 16);
    }
}
.portal-hero--has-margin-bottom {
    @media screen and (min-width: 1420px){
        margin-bottom: calc(300rem / 16);
    }
}

.hero-headline{
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
}

.hero-search{
    @media screen and (min-width: 1500px){
        position: absolute;
        left: 0;
        right: 0;

        /*this should not be used because of IE11*/
        /*transform: translateY(calc(-100% + 130px));*/
        /*use following code instead*/
        transform: translateY(-45%) translateY(5vw);
    }
}

.portal-hero__arrival{
    @media screen and (min-width:768px) {
        margin-right: calc(27rem / 16);
    }
}

.portal-hero-table{
    font-family: var(--font-default-bold);
    line-height: 1;
}

.portal-hero-table .portal-hero-table__additional-info{
    border: 2px solid var(--color-primary);
    border-radius: 50%;
    width: 22px;
    height: 22px;
}
.portal-hero-table__additional-info-icon{
    font-style: italic;
    margin-right: 2px;
}

.portal-hero-table thead tr td{
    border-top: none;
    border-bottom: 1px solid var(--color-primary);
    font-family: var(--font-default);
    font-size: calc(14rem / 16);
    padding-bottom: calc(6rem / 16);
}
.portal-hero-table tbody tr td{
    padding-top: calc(9rem / 16);
    padding-bottom: calc(11rem / 16);
    border-top: none;
    border-bottom: 1px dashed var(--color-primary);
}
.portal-hero-table tbody tr:last-of-type td{
    border-top: none;
    border-bottom: 1px solid var(--color-primary);
}
.portal-hero-table tr td:nth-child(4){
    @media screen and (max-width: 767px){
        display: none;
    }
}

.portal-hero-content.portal-hero-search{
    padding-left: calc(40rem / 16);
    padding-right: calc(40rem / 16);

    @media screen and (max-width: 767px){
        padding-left: calc(0rem / 16);
        padding-right: calc(0rem / 16);

        padding-top: calc(22rem / 16);
    }
}

.portal-hero-search .floating-label{
    left: calc(10rem / 16);
}

.portal-hero-content{
    padding-top: calc(22rem / 16);
    padding-bottom: calc(22rem / 16);

    @media screen and (max-width: 767px){
        padding-top: calc(0rem / 16);
        /*padding-bottom: calc(0rem / 16);*/
    }
}

.hero-image__img{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @media screen and (max-width: 767px){
        position: relative;
        transform: translateY(0);
    }
}

.hero-image__icon-size{
    width: calc(265rem / 16);
    height: calc(265rem / 16);
}

.hero-image__min-height{
    @media screen and (max-width: 767px){
        padding: calc(10rem/16) 0;
    }
    @media screen and (min-width: 768px){
        min-height: calc(500rem / 16);
    }
}

.hero-image__heading {
    @media screen and (max-width: 767px){
        margin-bottom:0;
    }
}

.hero-image__icon{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 200px;
    pointer-events: none;
}

.hero-image__heading{
    margin-bottom: calc(15rem / 16);
}

.hero-search__container{
    box-shadow: 0 10px 20px 0 rgba(43,33,113,0.1);
}

.hero-search__container .tab-content>.tab-pane{
    @media screen and (min-width: 768px){
        display: block;
        opacity: 1;
    }
}

.hero-search__container .nav-pills .nav-link.active{
    background-color: transparent;
    color: var(--color-primary);

    @media screen and (max-width: 767px){
        background-color: var(--color-secondary);
        border-radius: 0;
    }
}

.hero-search__container .nav-pills .nav-link{
    color: var(--color-light-grey);
}

.hero-search__container .nav-pills .nav-link:hover,
.hero-search__container .nav-pills .nav-link:hover div{
    @media screen and (max-width: 767px){
        text-decoration: none;
    }
}

.hero-search__tab-icon{
    font-size: calc(40rem / 16);
}

.portal-hero__headline-underline:after{
    content: '';
    margin-top: calc(10rem / 16);
    display: block;
    height: 3px;
    background-color: var(--color-primary);
    width: calc(47rem / 16);
}

.portal-hero--icon{
    font-size: calc(45rem / 16);
}

.portal-hero__search{
    margin-bottom: calc(20rem / 16);
}
.portal-hero__flight{
    margin-bottom: calc(26rem / 16);
}

.hero-search-border-left{
    @media screen and (min-width: 1200px) {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }
}
.hero-search-border-right{
    @media screen and (min-width: 1200px) {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }
}

/*-------------- hero-search__container iframe ------------*/
.hero-search.hero-search--iframe {
}
.hero-search__container.hero-search__container-iframe {
}