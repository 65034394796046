.embed-responsive-slider-vacation-wide{
    &::before {
         padding-top: 64.99283%;
        @media screen and (max-width: 767px) {
            padding-top: 135.22388%;
        }
     }
}
.embed-responsive-cargo-map{
    &::before {
        padding-top: 45%;
        @media screen and (max-width: 767px) {
            padding-top: 135%;
        }
    }
}
.embed-responsive-slider-vacation-slim{
    &::before {
         padding-top: 135.22388%;
     }
}

.embed-responsive-blog-teaser-big{
    &::before {
         padding-top: 90.28169%;
     }
}

.embed-responsive-blog-teaser-small{
    &::before {
         padding-top: 81.77215%;
     }
}

.embed-responsive-additional-info-image{
    &::before {
         padding-top: 83.42776%
     }
}

.embed-responsive-content-hero{
    &::before {
         padding-top: 52.02914%
     }
}

.embed-responsive-blog-detail-hero{
    &::before {
        padding-top: 81.16545%;
        @media screen and (max-width: 767px) {
            padding-top: 52%;
        }
    }
}

.embed-responsive-portal-hero{
    &::before {
         padding-top: 45.83333%;
        @media screen and (max-width: 575px) {
            padding-top: 85.5%;
        }
     }
}

.embed-responsive-slider-grid-teaser{
    &::before {
         padding-top: 79.58937%;
        @media screen and (max-width: 575px) {
            padding-top: 78.125%;
        }
    }
}

.embed-responsive-content-image{
    &::before {
         padding-top: 66.63113%
     }
}

.embed-responsive-wysiwyg-special{
    &::before {
         padding-top: 62.24784%;
     }
}

.embed-responsive-img-block{
    &::before {
         padding-top: 57.774%;
     }
}

.embed-responsive-blog-teaser-small-wide{
    &::before {
        padding-top: 39.58333%;
        @media screen and (max-width: 575px) {
            padding-top: 81.77215%;
        }
     }
}

.embed-responsive-blog-teaser-small-high{
    &::before {
        padding-top: 132.1%;
        @media screen and (max-width: 575px) {
            padding-top: 81.77215%;
        }
     }
}



/* cover */
.embed-responsive-cover {
    height:100%;
}
.embed-responsive-cover .embed-responsive-item {
    width:auto;
    min-height:100%;
    left:50%;
    transform: translateX(-50%);
}

@media screen and (min-width: 768px){
    .embed-responsive-cover-desktop {
        height:100%;
    }
    .embed-responsive-cover-desktop .embed-responsive-item {
        width:auto;
        min-height:100%;
        left:50%;
        transform: translateX(-50%);
    }
}