/* Text Helper */
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


/* Text Color Helper */
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }


/* Background Color Helper */



/* Additional */
.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}

.increased-click-area {
    padding: calc(8rem/16);
    margin: calc(-8rem/16);
}

.link-arrow{
    font-size: calc(20rem / 16);
}

.w-md-50{
    @media screen and (min-width: 768px){
        width: 50% !important;
    }
}

.word-break-initial{
    word-break: initial;
}

.color-inherit{
    color: inherit !important;
}

.btn-icon-text:hover,
.btn-icon-text:active{
    text-decoration: none;
}
/*.btn-icon-text:hover *:not(.icon),
.btn-icon-text:active *:not(.icon){
    text-decoration: underline;
}*/

.tooltip > .tooltip-inner{
    color: var(--color-primary);
    font-family: var(--font-default);
    background-color: white;
    border: 1px solid var(--color-primary);
    border-radius: 3px;
    font-size: calc(15rem / 16);
    opacity: 1;
}

a:hover *:not(.icon),
a:active *:not(.icon) {
    text-decoration: underline;
}

.dropup .dropdown-toggle:after{
    content: none;
}

.form-control.js-datepicker__alt-field,
.form-control.js-floating-label{
    padding-top: calc(11rem / 16);
    padding-bottom: 1px;
}

.mx-md-n3{
    @media screen and (min-width: 768px){
        margin-left: -1rem;
        margin-right: -1rem;
    }
}

.background-clip-border-box{
    background-clip: border-box;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}