.bullet-points__headline {
    font-family: var(--font-default);
    font-size: calc(16rem / 16);
    line-height: calc(18rem / 16);
    margin-top: calc(10rem / 16);
    margin-bottom: 0;

    @media screen and (min-width: 768px) {
        font-size: calc(22rem / 16);
        line-height: calc(35rem / 16);
        margin-top: calc(15rem / 16);
        margin-bottom: calc(5rem/16);
    }
}

.bullet-points__list {
    margin-left: calc(26rem / 16);

    @media screen and (min-width: 768px) {
        margin-left: calc(36rem / 16);
    }
}

.bullet-points__list-item {
    font-size: calc(15rem / 16);
    line-height: calc(23rem / 16);
    margin-bottom: calc(0.3rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
        line-height: calc(26rem / 16);
        margin-bottom: calc(8rem/16);
    }
}

.bullet-points__list .bullet-points__list-item::marker {
    height: calc(7rem / 16);
    width: calc(7rem / 16);
    color: var(--color-primary);

    @media screen and (min-width: 768px) {
        height: calc(10rem / 16);
        width: calc(10rem / 16);
    }
}