.tt-menu.tt-open{
    background-color: white !important;
    padding: 0 !important;
    border: 1px solid black;



    max-height: calc( 100vh - 100px );
    overflow: auto;
}

.twitter-typeahead{
    position: initial !important;
}

.tt-menu .tt-dataset{
    border-top: 1px solid var(--color-primary);
}

.tt-menu .typeahead-heading{
    border-bottom: 1px solid var(--color-light-grey);
    margin: 0 calc(20rem / 16) !important;
    padding: calc(10rem / 16) 0;
    text-transform: uppercase;
}

.tt-menu .typeahead-suggestion{
    padding-left: 0;
}

.tt-suggestion.tt-selectable{
    padding: calc(10rem / 16) 0 !important;
    margin: 0 calc(20rem / 16) !important;
    text-align: left;
}

.tt-suggestion.tt-selectable:not(:last-of-type){
    border-bottom: 1px solid var(--color-light-grey);
}

.tt-menu .tt-selectable.tt-cursor{
    color: var(--color-primary);
    text-decoration: underline;
}

.tt-menu{
    font-size: calc(14rem / 16);
}