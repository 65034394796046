.video-js .vjs-big-play-button{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    border: none;
    background-color: transparent;
    width: calc(175rem / 16);
    height: calc(160rem / 16);
}

.video-js .vjs-big-play-button .vjs-icon-placeholder{
    width: calc(100rem / 16);
    height: calc(100rem / 16);
}
.video-js .vjs-big-play-button .vjs-icon-placeholder:before{
    font-family: iconfont;
    /*content: var(--icon-play);*/
    content: url("/static/img/play-video.svg");
}

.video-js:hover .vjs-big-play-button{
    background-color: transparent;
}