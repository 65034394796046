html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        
        overflow-x: hidden;
    }
}
.container.container {
    max-width: calc(1420rem/16);
    width: 100%;
}

.container-slim{
    width: 100%;
    max-width: 939px;
    padding-right: 1rem;
    padding-left: 1rem;
    margin-right: auto;
    margin-left: auto;
}

.container-narrow{
    width: 100%;
    max-width: 650px;
    padding-right: 1rem;
    padding-left: 1rem;
    margin-right: auto;
    margin-left: auto;
}

.container-wide{
    width: 100%;
    max-width: 1920px;
    /*padding-right: 1rem;*/
    /*padding-left: 1rem;*/
    margin-right: auto;
    margin-left: auto;
}

.container-wide--overflow-hidden {
    overflow-x: hidden;
}

#main-content{
    /*overflow-x: hidden;*/
}