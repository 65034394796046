.cargo-map {
    position: relative;
}
.cargo-map__filter {
    color: white;
    position: absolute;
    top: calc(10rem/16);
    padding: calc(20rem/16);
    justify-content: center;

    @media screen and (min-width: 768px){
        right: calc(40rem/16);
        padding: 0;
        top: calc(30rem/16);
    }
}

.cargo-map__filter-element {
    margin-left: calc(4rem/16);
    margin-right: calc(4rem/16);
    text-align: center;
    font-family: var(--font-default-bold);

    position: relative;
    background-color: white;
    color: var(--color-primary);
    padding: 0.5rem 1rem 0.5rem 2.2rem;
    border-radius: calc(50rem/16);
    width: 9rem;
    box-shadow: 0 0 10px 0 rgba(43,33,113,.1);

    @media screen and (min-width: 768px){
        padding: 0.75rem 2rem 0.75rem 3rem;
        border-radius: calc(50rem/16);
        width: auto;
        margin-left: calc(16rem/16);
        margin-right: 0;
    }
}

.cargo-map__filter-checkbox-style {
    position: absolute;
    top: calc(10rem/16);
    left: calc(14rem/16);
    height: calc(18rem/16);
    width: calc(18rem/16);
    background-color: #cecece;
    border-radius: 100%;

    @media screen and (min-width: 768px){
        top: 1rem;
        left: 1.125rem;
    }
}

.cargo-map__filter-element .cargo-map__filter-checkbox:checked ~ .cargo-map__filter-checkbox-style--green {
    background-color: #9B57D3;
}
.cargo-map__filter-element .cargo-map__filter-checkbox:checked ~ .cargo-map__filter-checkbox-style--yellow{
    background-color: yellow;
}
.cargo-map__filter-element .cargo-map__filter-checkbox:checked ~ .cargo-map__filter-checkbox-style--orange{
    background-color: orange;
}
.cargo-map__filter-element .cargo-map__filter-checkbox:checked ~ .cargo-map__filter-checkbox-style--red{
    background-color: red;
}

.cargo-map__filter-element:after {
    content: "";
    position: absolute;
    display: none;
}
.cargo-map__filter-element .cargo-map__filter-checkbox:checked ~ .cargo-map__filter-checkbox-style:after {
    display: block;
}
.cargo-map__filter-element .cargo-map__filter-checkbox-style:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 5px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.cargo-map__legend-element {
    padding: 0 calc(10rem/16);
    text-align: center;
}

.cargo-map__loading__spinner {
    margin-bottom: calc(287rem/16);
}

.dot--red, .dot--green, .dot--orange, .dot--yellow {
    height: 0.75rem;
    width: 0.75rem;
    border-radius: 50%;
    display: inline-block;
}
.dot--red {
    background-color: red;
}
.dot--green {
    background-color: #9B57D3;
}
.dot--orange {
    background-color: darkorange;
}
.dot--yellow {
    background-color: yellow;
}

.cargo-map-infobox__container {
    width: 315px;
    @media (min-width: 768px) {
        width: 340px;
    }
}

.cargo-map-infobox__container img {
    padding: calc(5rem/16);
}

.cargo-map-infobox{
    background: #fff;
    box-shadow: 0 calc(-20rem/16) calc(12rem/16) rgba(0,0,0,.1);
    border-radius: calc(2rem/16);
    padding: calc(20rem/16) calc(50rem/16) calc(20rem/16) calc(20rem/16);
    position: relative;
    z-index: -5;
}
.cargo-map-infobox__headline {
    font-family: var(--font-default-bold);
    font-size: var(--font-size-default);
    color: var(--color-secondary-dark);
    padding-bottom: calc(4rem/16);
}
.cargo-map-infobox__description {
    font-size: calc(13rem/16);
    @media (min-width: 768px) {
        font-size: calc(14rem/16);
    }
}
.cargo-map-infobox__description-hours {
    display: inline-block;
    margin-left: calc(8rem/16);
@media (min-width: 768px) {
    margin-left: calc(15rem/16);
}
}
.cargo-map-infobox__arrow {
    /*down
    width: 0;
    height: 0;
    border-left: calc(10rem/16) solid transparent;
    border-right: calc(10rem/16) solid transparent;

    border-top: calc(10rem/16) solid #f00;
     */

    width: 0;
    height: 0;
    border-left: calc(10rem/16) solid transparent;
    border-right: calc(10rem/16) solid transparent;

    border-bottom: calc(10rem/16) solid white;

    position: absolute;
    top: calc(-10rem/16);
}