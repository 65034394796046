.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: 2em;
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a:not(.btn) {
    color: var(--color-primary);
}
.wysiwyg a:not(.btn):hover,
.wysiwyg a:not(.btn):focus,
.wysiwyg a:not(.btn):active {
    color: var(--color-primary);
    text-decoration: underline;
}

.wysiwyg-area__content{
    margin-top: calc(60rem / 16);
    @media screen and (max-width: 767px){
        margin-top: calc(15rem / 16);
    }
}
.cookie-modal__label ul,
.wysiwyg ul{
    list-style: none;
    margin-left: 20px;
    padding-left: 0;
}
.cookie-modal__label ul li,
.wysiwyg ul li,
.wysiwyg ol li{
    margin-left: 0;
    padding-left: calc(25rem / 16);
    position: relative;
}
.cookie-modal__label ul li:before,
.wysiwyg ul li:before{
    content: '';
    position: absolute;
    top: .5em;
    left: 0;
    bottom: 0;
    width: 10px;
    height: 10px;
    background-color: var(--color-primary);
    border-radius: 50%;
}

.cookie-modal__label ul li:before{
    top: .375em;
}

.wysiwyg ol{
    counter-reset: list;
    padding-left: 0;
    margin-left: 20px;
    list-style: none;
}
.wysiwyg ol li:before{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;

    width: auto;
    counter-increment: list;
    color: var(--color-primary);
    content: counter(list);
    font-family: var(--font-default-bold);
}

.wysiwyg a:not(.btn){
    font-family: var(--font-default-bold);
    position: relative;
    padding-left: calc(21rem / 16);
}
.wysiwyg a:not(.btn):before{
    font-family: iconfont;
    content: var(--icon-arrow_big-right);
    position: absolute;
    left: 0;
    top: .75rem;
    font-size: calc(20rem / 16);
    line-height:1px;
    @media screen and (max-width: 767px) {
        top: .5rem;
    }
}

.wysiwyg-with-medium__content{
    padding-top: calc(72rem / 16);
    padding-bottom: calc(55rem / 16);
}

.wysiwyg__specialContent{
    box-shadow: 0 30px 50px 0 rgba(43,33,113,0.2);
    border-radius: 3px;
    
    padding: calc(60rem / 16) calc(94rem / 16);

    @media screen and (max-width: 767px){
        padding: calc(30rem / 16) calc(20rem / 16);
    }
    @media screen and (min-width: 768px) and (max-width: 991px){
        padding: calc(60rem / 16) calc(47rem / 16);
    }
}
.wysiwyg__specialContent-img{
    margin-top: calc(37rem / 16);
    margin-bottom: calc(37rem / 16);
}