.additional-info{
    min-height: calc(239rem / 16);
    box-shadow: 0 10px 20px 0 rgba(43,33,113,0.1);

    padding: calc(25rem / 16) calc(25rem / 16) calc(25rem / 16) calc(40rem / 16);

    @media screen and (max-width: 767px){
        padding: calc(18rem / 16) calc(16rem / 16);
        min-height: initial;
    }
}

.additional-info__link{
    margin-bottom: calc(11rem / 16);
}

.additional-info__container{
    @media screen and (min-width: 768px){
        padding-top: calc(80rem / 16);
        padding-bottom: calc(120rem / 16);
    }
}

.additional-info__icon{
    margin-bottom: calc(20rem / 16);
    font-size: calc(54rem / 16);

    @media screen and (max-width: 767px){
        font-size: calc(32rem / 16);
        margin-bottom: calc(14rem / 16);
    }
}
.downloads__icon{
    @media screen and (max-width: 1199px){
        margin-right: calc(20rem / 16);
    }
    margin-right: calc(37rem / 16);
}

.downloads__heading{
    margin-bottom: calc(30rem / 16);
}

.downloads{
    box-shadow: 0 10px 20px 0 rgba(43,33,113,0.1);
    padding: calc(44rem / 16) calc(44rem / 16);
    position: relative;

    @media screen and (max-width: 767px){
        padding: calc(15rem / 16) calc(15rem / 16);
    }
}
.downloads--wide{
    min-width: calc(33% - 29px);
    max-width: calc(33% - 29px);
    @media screen and (max-width: 767px){
        min-width: 100%;
        max-width: 100%;
    }
}
.downloads__container{
    padding-top: calc(67rem / 16);
    padding-bottom: calc(105rem / 16);
    @media screen and (max-width: 767px){
        padding-top: calc(15rem / 16);
        padding-bottom: calc(15rem / 16);
    }
}
.downloads:hover,
.downloads:active{
    box-shadow: 0 10px 20px 0 rgba(43,33,113,.2);
}
.downloads:hover *:not(.icon),
.downloads:active *:not(.icon){
    text-decoration: underline;
}

.airline-contact__text{
    margin-bottom: calc(70rem / 16);
    @media screen and (max-width: 767px){
        margin-bottom: calc(0rem / 16);
    }
}

.airline-contact:not(:last-of-type){
    margin-bottom: calc(35rem / 16);

    @media screen and (max-width: 767px){
        margin-bottom: calc(15rem / 16);
    }
}

.additional-info-image__container{
    @media screen and (min-width: 768px){
        padding-top: calc(55rem / 16);
        padding-bottom: calc(55rem / 16);
    }
}

.additional-info-image{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;

    @media screen and (max-width: 767px){
        position:relative;
    }
}

.airline-logo{
    height: calc(50rem / 16);
    margin-bottom: calc(26rem / 16);

    @media screen and (max-width: 767px){
        margin-top: calc(30rem / 16);
        margin-bottom: calc(15rem / 16);
    }
}

.airline-logo img{
    width: calc(190rem / 16);
    height: auto;
}