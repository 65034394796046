.slider-vacation .slick-list {
    margin: 0 -13px;
}

.slider-vacation .slick-slide {
    margin: 0 13px;
}

.slider-vacation .card{
    border: 0;
    margin-bottom: calc(20rem / 16);
    box-shadow: 0 10px 20px 0 rgba(43,33,113,0.1);

    width: 335px !important;
    @media screen and (max-width: 767px) {
        width: 126px !important;
    }
}
.slider-vacation .card:hover,
.slider-vacation .card:active,
.slider-vacation .card:focus{
    box-shadow: 0 10px 10px 0 rgba(43,33,113,.2);
}

.slider-vacation .card.slider-vacation__card-wide{
    width: 697px !important;
    @media screen and (max-width: 767px) {
        width: 126px !important;
    }
}

.slider-vacation__icon-slide-icon{
    transform: translateX(22%);
    font-size: 16em;
    opacity: .8;
}

.swiper-wrapper{
    z-index: 0;
}

.swiper-button-next:after,
.swiper-button-prev:after{
    content: none;
}

.swiper-slide.card{
    margin-right: calc(26rem / 16);
    border-radius: 3px;
}
.swiper-slide.card .embed-responsive{
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}