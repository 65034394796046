.hotspot-map{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.hotspot__arrow{
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 12px;
    border-color: transparent;
    position: relative;
    left: 0;
    top: -14px;
    z-index: 1;
    cursor: pointer;
}

.hotspot.is-active .hotspot__arrow{
    border-color: transparent transparent #fff;
    z-index: 2;
}

.hotspot__box{
    @media screen and (min-width: 768px){
        background: #fff;
        width:15rem;
        box-shadow: 0 -20px 12px rgba(0,0,0,.1);
        border-radius: 2px;
        padding: 20px 50px 20px 20px;
        position: relative;
        visibility: hidden;
        opacity: 0;
        z-index: 1;
        transform: translateY(-5px);
    }
}

.hotspot__box-text-center{
    margin-left: 15px;
}

.hotspot.is-active .hotspot__box{
    visibility: visible;
    opacity: 1;
}