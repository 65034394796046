@media screen and (max-width: 767px){
    .ui-datepicker,
    .ui-datepicker td a,
    .ui-datepicker td span,
    .ui-datepicker th{
        padding: 0;
    }

    .ui-datepicker{
        width: 14em;
    }
}