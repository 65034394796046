.cookie-bar{
    background-color: var(--color-primary);
    box-shadow: 0 -10px 20px 0 rgba(43,33,113,.2);
    border-top: 1px solid var(--color-secondary);
}

.cookie-bar__detail-link{
    font-size: calc(18rem/16);
    @media screen and (max-width: 767px){
        font-size: calc(15rem/16);
    }
}

@media screen and (min-width: 768px) {
    .cookie-modal .modal-header .close {
        font-size: 2.5rem;
    }
    .cookie-bar__buttons {
        margin-top: 0;
        text-align: center;
    }
}

.cookie-modal__item.cookie-modal__item, .cookie-modal__item--all.cookie-modal__item--all{
    margin-bottom: 1.5rem;
}
.cookie-modal__title {
    font-size: 1.5rem;

    @media screen and (min-width: 768px) {
        font-size: calc(32rem/16);
    }
}
.cookie-modal__label {
    font-size: calc(18rem/16);
    font-family: var(--font-default-medium);
    @media screen and (min-width: 768px) {
        font-size: calc(20rem/16);
        line-height: calc(22rem/16);
    }
}
.cookie-modal__label p,
.cookie-modal__label ul {
    padding-left: 4.5rem;
    line-height: calc(20rem/16);
    font-size: calc(16rem/16);
    font-family: var(--font-default);
    @media screen and (min-width: 768px) {
        padding-left: 4rem;
        font-size: calc(16rem/16);
        line-height: calc(20rem/16);
    }
}
.cookie-modal__label ul {
    margin: 0;
}
.cookie-modal__label p {
    margin-bottom: calc(6rem/16);
    @media screen and (min-width: 768px) {
        margin-bottom: calc(8rem/16);
    }
}
.cookie-modal__label p:last-of-type {
    margin-bottom: 0;
}

.cookie-modal__switch {
    height: calc(30rem/16);
    @media screen and (min-width: 768px) {
        width: calc(50rem/16);
        height: calc(30rem/16);
    }
}
.cookie-modal__switch-toggle:before {
    bottom: calc(4rem/16);
    width:calc(22rem/16);
    height:calc(22rem/16);
    @media screen and (min-width: 768px) {
        bottom: calc(4rem/16);
    }
}

.cookie-modal__switch input:checked + .cookie-modal__switch-toggle:before {
    @media screen and (min-width: 768px) {
        transform: translateX(20px);
    }
}
