.slider-grid-cart-container{
    max-width: calc(1056rem / 16);
    width: 100%;

    margin-top: calc(67rem / 16);
    margin-bottom: calc(64rem / 16);

    @media screen and (max-width: 767px){
        margin-top: calc(-80rem / 16);
        margin-bottom: 0;
    }
}

.slider-grid-cart__width{
    max-width: calc(315rem / 16);
    width: calc(315rem / 16);
    margin-left: calc(25rem / 16);
    margin-bottom: calc(25rem / 16);

    @media screen and (max-width: 767px){
        margin: 0 calc(2rem / 16) calc(5rem / 16);

        max-width: calc(50% - 4px);
        width: calc(50% - 4px);
    }
}

.slider-grid-cart__image{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @media screen and (max-width: 767px){
        position: relative;
    }
}

.slider-grid-cart__carts{
    position: relative;
    z-index: 1;
}

.slider-grid-teaser .slick-prev.slick-arrow,
.slider-grid-teaser .slick-next.slick-arrow{
    display: none !important;
}

.slider-grid-teaser ul.slick-dots{
    margin-top: 0;
    transform: translateY(-54px)translateX(10%);
    height: 0;

    @media screen and (max-width: 767px){
        display: none !important;
    }
    @media screen and (max-width: 1199px){
        transform: translateY(-54px)translateX(0%);
    }
}

.slider-grid-teaser__nav{
    position: relative;
    z-index: 1;
}
.slider-grid-teaser__nav .slider-grid-teaser__nav-position{
    @media screen and (min-width: 1400px){
        transform: translateY(100%);
    }
}

.slider-grid-teaser__nav .slider-grid-teaser__nav-position button:not(:last-of-type){
    @media screen and (min-width: 768px){
        margin-right: calc(20rem / 16);
    }
}

.slider-grid-teaser__nav-icon{
    font-size: calc(34rem / 16);
}

.slider-grid-teaser__nav .dropdown.show .dropdown-toggle{
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.slider-grid-teaser__nav .dropdown.show .dropdown-menu{
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    border: 1px solid var(--color-primary);
    border-top: none;
}
.slider-grid-teaser__nav .dropdown{
    margin-bottom: calc(0rem / 16);
}

.slider-grid-teaser__affix{
    z-index: 1;
}