/*
.loading-spinner {
    margin-left: auto;
    margin-right: auto;
    width: 70px;
    text-align: center;
}
.loading-spinner__item {
    width: 18px;
    height: 18px;
    background-color: var(--color-primary);

    border-radius: 100%;
    display: inline-block;
    animation: loading-spinner-rotate 1.4s infinite ease-in-out both;
}
.loading-spinner__item--1 {
    animation-delay: -0.32s;
}
.loading-spinner__item--2 {
    animation-delay: -0.16s;
}
@keyframes loading-spinner-rotate {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1.0);
    }
}*/

.loading {
    position: absolute;
    bottom: 11px;
    z-index: 1000;
    left: 50%;
    margin-left: -60px;
    height: 30px;
    width: 120px;
    overflow: hidden;
    border-bottom: 2px solid #fff;
    color: var(--color-primary);
    background-color: #ffffffa6;
}
.loading__spinner {
    font-size: 22px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    animation: fly 1.2s infinite ease-in-out;
    color: var(--color-primary);
}
.loading__spinner>.icon {
    transform: rotate(90deg);
}
@keyframes fly {
    0%, 80.00001% { transform: translateX(-30%) }
    80% { transform: translateX(100%)}
    100% { transform: translateX(-30%)}
}