.footer-spacing{
    padding-top: calc(65rem / 16);
    padding-bottom: calc(40rem / 16);
}

.footer-divider{
    border-bottom: 1px solid white;
    padding-bottom: calc(33rem / 16);
    margin-bottom: calc(33rem / 16);
}

.footer__contact{
    margin-top: calc(20rem / 16);
}

.footer__open-times{
    margin-top: calc(42rem / 16);
}

.footer__contact-mobile{
    @media screen and (max-width: 767px){
        padding: calc(13rem/16) calc(23rem/16);
        border: 2px solid #FFFFFF;
        border-radius: 30px;
    }
}

.subfooter-spacing{
    margin-top: calc(22rem / 16);
    margin-bottom: calc(22rem / 16);
    
    font-size: calc(16rem / 16);
    @media screen and (max-width: 767px) {
        font-size: calc(15rem / 16);
    }
}

.subfooter-mobile-spacing{
    @media screen and (max-width: 767px) {
        line-height: 1;
    }
}
.subfooter-mobile-spacing .list-inline-item{
    @media screen and (max-width: 767px) {
        font-size: calc(12rem / 16);
    }
}
.subfooter-mobile-spacing:not(:last-of-type){
    @media screen and (max-width: 767px) {
        margin-bottom: calc(19rem / 16);
    }
}

.social-icon-footer{
    width: calc(45rem / 16);
    min-width: calc(45rem / 16);
    height: calc(45rem / 16);
    background-color: var(--color-secondary);
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content:center;
    transition-duration: .25s;
}
.social-icon-footer:hover,
.social-icon-footer:focus{
    background-color: var(--color-primary);
    color: var(--color-secondary);
    border: 1px solid var(--color-secondary);
    transition-duration: .25s;
}

.footer-main__partner .list-inline-item{
    @media screen and (min-width: 992px){
        margin-right: calc(40rem / 16);
    }
}

.footer-main__partner img {
    max-width: 50px;
    max-height: 35px;
    width: auto;
    height: auto;

    @media screen and (min-width: 1200px) {
        max-width: 66px;
    }
}

.footer__brand-img{
    width: calc(186rem / 16);
    height: calc(66rem / 16);

    @media screen and (max-width: 767px) {
        margin-bottom: calc(40rem / 16);
        width: calc(146rem / 16);
        height: calc(52rem / 16);
    }
}

.footer__elements-logo{
    width: calc(150rem / 16);
    height: calc(17rem / 16);

    @media screen and (max-width: 767px) {
        width: calc(100rem / 16);
        height: calc(11rem / 16);
    }
}