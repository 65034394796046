.flight-search__container{
    max-width: calc(456rem / 16);

    margin-top: calc(15rem / 16);
    margin-bottom: calc(15rem / 16);
    width: 100%;

    @media screen and (max-width: 767px){
        margin-right: auto;
    }
    @media screen and (min-width: 768px) and (max-width: 1399px){
        max-width: none;
    }
    @media screen and (min-width: 1401px){
        margin-left: auto;
        width: calc(456rem / 16);
    }
}

.flight-search-position{
    @media screen and (min-width: 1530px){
        width: calc(706rem / 16);
        margin-left: auto;

        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.flight-search__display{
    display: block;
    @media screen and (min-width: 1400px){
        display: flex;
    }
}

.flight-search__headline{
    @media screen and (min-width: 768px) and (max-width: 1399px){
        margin-top: calc(10rem / 16);
    }
}

.flight-search__switch{
    @media screen and (min-width: 768px) and (max-width: 1399px){
        display: flex;
        align-items: center;
    }
}

.flight-search__form{
    @media screen and (min-width: 768px) and (max-width: 1399px){
        display: flex;
    }
}
.switch-btn{
    @media screen and (min-width: 768px) and (max-width: 1399px){
        display: none;
    }
}

.flight-search__container-text{

    @media screen and (max-width: 1199px){
        width: 100%;
        padding-left: calc(16rem/16);
        padding-right: calc(16rem/16);
    }
    @media screen and (min-width: 768px){
        max-width: calc(335rem / 16);
        margin-right: auto;
    }
    @media screen and (min-width: 768px) and (max-width: 1399px){
        width: auto;
        max-width: none;
    }
    @media screen and (min-width: 1401px){
        width: calc(335rem / 16);
    }
}

.flight-search__img{
    max-width: 1214px;
    @media screen and (min-width: 768px) and (max-width: 1399px){
        max-width: 1399px;
    }
}
.flight-search__links{
    @media screen and (min-width: 768px) and (max-width: 1399px){
        display: flex;

        margin-top: calc(10rem / 16);
        margin-bottom: calc(10rem / 16);
    }
}
.flight-search__links a{
    @media screen and (min-width: 768px) and (max-width: 1399px){
        margin-bottom: 0 !important;
    }
}

.flight-search__switch-btn{
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translateY(-50%);

    width: 40px;
    height: 40px;
    padding: 0;
}

.flight-search__submit{
    margin-top: calc(30rem / 16);
    margin-bottom: calc(60rem / 16);

    @media screen and (min-width: 768px) and (max-width: 1399px){
        margin: 0;
    }
}

.form-control.flight-search__input{
    padding-left: calc(55rem / 16);
}

.flight-search__input--width{
    @media screen and (min-width: 768px) and (max-width: 1399px){
        min-width: calc(300rem / 16);
    }
}

.input-spacing{
    @media screen and (min-width: 768px) and (max-width: 1399px){
        padding-right: calc(15rem / 16);
    }
}

.flight-search__input-label{
    padding-left: calc(50rem / 16);
}

.flight-search__input-icon{
    position: absolute;
    left: calc(10rem / 16);
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-primary);
}

.flight-search__input-spacing{
    margin-bottom: calc(10rem / 16);
}
.flight-search__input-spacing--flex{
    @media screen and (min-width: 768px) and (max-width: 1399px){
        margin-bottom: 0;
    }
}

.flight-search__input-spacing.flight-search__input-spacing-hero{
    @media screen and (min-width: 768px) and (max-width: 1199px){
        margin-bottom: 0;
    }
}

.flight-search__number{
    @media screen and (min-width: 768px) and (max-width: 1199px){
        margin-bottom: calc(0rem / 16);
    }
}

.flight-search__switch-left{
    position: absolute;
    top: 3px;
    bottom: 0;
    left: 12px;
    font-size: calc(20rem / 16);
}
.flight-search__switch-right{
    position: absolute;
    top: -3px;
    bottom: 0;
    right: 12px;
    font-size: calc(20rem / 16);
}

.flight-search__subline{
    margin-top: calc(22rem / 16);
    margin-bottom: calc(30rem / 16);
}

.flight-search__tabs{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    
    margin-top: calc(30rem / 16);
}

.flight-search__tabs .nav-item{
    margin: 0 calc(10rem / 16);
}

.flight-search__tabs .nav-link{
    border-width: 0;
}

.flight-search__tablet-input{
    @media screen and (min-width: 768px) and (max-width: 1199px){
        min-width: calc(550rem/16);
    }
}