.job-detail {
    margin-top: calc(17rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(60rem/16);
    }
}

.job-detail__title {
    font-size: calc(18rem / 16);
    line-height: calc(22rem / 16);
    font-family: var(--font-default-medium);

    @media screen and (min-width: 768px) {
        font-size: calc(32rem / 16);
        line-height: calc(35rem / 16);
        font-weight: bold;
    }
}

.job-detail__area-heading {
    font-family: var(--font-default);
    font-size: calc(16rem / 16);
    line-height: calc(18rem / 16);
    margin-top: calc(10rem / 16);
    margin-bottom: 0;

    @media screen and (min-width: 768px) {
        font-size: calc(22rem / 16);
        line-height: calc(35rem / 16);
        margin-top: calc(15rem / 16);
    }
}

.job-detail__print-icon {
    font-size: calc(22.5rem/16);
    margin-top: calc(18rem/16);
    transition: transform 0.2s ease-in-out;

    @media screen and (min-width: 768px) {
        margin-top: calc(28rem/16);
    }
}

.job-detail__print-icon:hover {
    transform: scale(1.2);
    color: var(--color-primary);
}

.job-detail__print-icon,
.job-detail__print-icon:focus,
.job-detail__print-icon:hover {
    color: var(--color-primary);
}

.job-detail__content-wrapper {
    margin-top: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(40rem / 16);
    }
}

.job-detail__content {
    margin-bottom: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(25rem / 16);
    }
}

/*@media screen and (min-width: 768px) {
    .job-detail__headline {
        font-size: calc(32rem / 16);
        line-height: calc(35rem / 16);
        font-weight: bold;
    }

    .job-detail__print-icon {
        margin-top: calc(28rem/16);
    }

    .job-detail__detail-headline {
        font-size: calc(22rem / 16);
        line-height: calc(35rem / 16);
        margin-top: calc(15rem / 16);
    }

    .job-detail__list {
        margin-left: calc(36rem / 16);
    }

    .job-detail__list-item {
        font-size: calc(18rem / 16);
        line-height: calc(26rem / 16);
    }

    .job-detail__list .job-detail__list-item::marker {
        height: calc(10rem / 16);
        width: calc(10rem / 16);
    }

    .job-detail__content-wrapper {
        margin-top: calc(40rem / 16);
    }

    .job-detail__content {
        margin-bottom: calc(25rem / 16);
    }
}*/