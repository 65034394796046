.card{
    border-radius: 0;
}

.slider-blog-be{
    margin-top: calc(120rem / 16);
}

/* the slides */
.slider-blog-detail .slick-slide,
.slider-blog .slick-slide {
    margin: 0 13px;
}
/* the parent */
.slider-blog-detail .slick-list,
.slider-blog .slick-list {
    margin: 0 -13px;
}

.slider-blog-detail .card,
.slider-blog .card{
    border: 0;
    margin-bottom: calc(20rem / 16);
    box-shadow: 0 10px 20px 0 rgba(43,33,113,0.1);
}

.slider-blog-detail .card:hover,
.slider-blog-detail .card:active,
.slider-blog .card:hover,
.slider-blog .card:active{
    box-shadow: 0 10px 10px 0 rgba(43,33,113,0.2);
}

.slider-blog--prev,
.slider-blog--next{
    width: 70px;
    height: 70px;
    background-color: #fff;
    border: 0;
    z-index: 1;
    border-radius: 50%;
    box-shadow: 0 10px 15px 0 rgba(43,33,113,0.2);

    position: absolute;
    top: 50%;
    transform: translateY(-50%);


    transition-duration: .25s;
    @media screen and (max-width: 767px){
        display:none !important;
    }
}

.slider-blog--prev.slick-disabled,
.slider-blog--next.slick-disabled{
    opacity: .25;
    pointer-events: none;
}

.slider-blog--prev:hover,
.slider-blog--next:hover,
.slider-blog--prev:focus,
.slider-blog--next:focus{
    color: white !important;
    background-color: var(--color-primary);
    transition-duration: .25s;
}

.slider-blog--prev{
    left: calc(-110rem / 16);

    /*width = container - width + prev/next btns*/
    @media screen and (max-width: 1640px){
        left: initial;
        right: 100px;
        top: -100px;
        transform: translateY(0);
    }
}
.slider-blog--next{
    right: calc(-110rem / 16);

    /*width = container - width + prev/next btns*/
    @media screen and (max-width: 1640px){
        right: 0;
        top: -100px;
        transform: translateY(0);
    }
}
.slider-blog__heading{
    height: calc(88rem / 16);
    max-height: calc(88rem / 16);
    @media screen and (max-width: 767px){
        height: calc(42rem / 16);
        max-height: calc(42rem / 16);
    }
}

.slider-blog__text{
    height: calc(54rem / 16);
}

.slider-grid-teaser__headline{
    @media screen and (max-width: 767px){
        margin-top: calc(30rem / 16);
    }
}

.slider-blog .slick-dots,
.slider-grid-teaser .slick-dots{
    list-style: none;
    display: flex;
    justify-content: center;
    margin-top: calc(60rem / 16);

    padding: 0;
    @media screen and (max-width: 767px){
        margin-top: calc(20rem / 16);
    }
}

.slider-blog .slick-dots li,
.slider-grid-teaser .slick-dots li{
    font-size: 0;
    width: 15px;
    height: 15px;
    margin: 0;
    border-radius: 50%;
}
.slider-blog .slick-dots li button,
.slider-grid-teaser .slick-dots li button{
    width: 15px;
    height: 15px;
    padding: 0;
    margin: 0;
    border: 5px solid white;
    background-color: #D0D0D0;
    border-radius: 100px;
}
.slider-grid-teaser .slick-dots li button{
    border: 5px solid var(--color-dark);
}

.slider-blog .slick-dots li.slick-active,
.slider-grid-teaser .slick-dots li.slick-active{
    width: 35px;
}
.slider-blog .slick-dots li.slick-active button,
.slider-grid-teaser .slick-dots li.slick-active button{
    width: 35px;
}
.slider-blog .slick-dots li.slick-active button,
.slider-grid-teaser .slick-dots li.slick-active button{
    background-color: var(--color-primary);
}

.slider-blog__card{
    border-radius: 3px;
    overflow: hidden;
    cursor: pointer;
}

.slider-blog__badge{
    top: 0;
    left: 0;
    background: white;
    padding: 5px;
    margin: 10px;
    display: flex;
    align-items: center;
    border-radius: 3px;
    font-size: calc(14rem / 16);
}

.slider-blog .card-body{
    margin-top: calc(30rem / 16);
    padding-top: 0;
}
.slider-blog .card-title{
    margin-bottom: calc(15rem / 16);
}

.slider-blog-detail .slick-list{
    overflow: visible;
}

.blog-detail-slider{
    position: absolute;
    bottom: 0;
    height: calc(90rem / 16);
    width: calc(45rem / 16);
    top: 50%;
    transform: translateY(-50%);

    background-color: white;
    border: none;
    color: var(--color-primary);
    box-shadow: 0 10px 15px 0 rgba(43,33,113,0.5);
    transition-duration: .25s;
}

.blog-detail-slider:not(.slick-disabled):hover,
.blog-detail-slider:not(.slick-disabled):focus{
    @media screen and (min-width: 768px){
        color: var(--color-secondary);
        background-color: var(--color-primary);
        transition-duration: .25s;
    }
}

.blog-detail-slider.slick-disabled{
    opacity: .5;
}
.blog-detail-slider.blog-detail-slider--next{
    right: 0;
    border-bottom-left-radius: 90px;
    border-top-left-radius: 90px;
}
.blog-detail-slider.blog-detail-slider--prev{
    left: 0;
    border-bottom-right-radius: 90px;
    border-top-right-radius: 90px;
}