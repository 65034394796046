@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1738746206676/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1738746206676/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1738746206676/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-addthis:before { content: "\EA01" }
.icon-arrival-new:before { content: "\EA02" }
.icon-arrival-round:before { content: "\EA03" }
.icon-arrival:before { content: "\EA04" }
.icon-arrow-left:before { content: "\EA05" }
.icon-arrow-right:before { content: "\EA06" }
.icon-arrow_big-right:before { content: "\EA07" }
.icon-arrow_extern:before { content: "\EA08" }
.icon-arrow_round-right:before { content: "\EA09" }
.icon-arrow_small-down:before { content: "\EA0A" }
.icon-arrow_small-right:before { content: "\EA0B" }
.icon-bag:before { content: "\EA0C" }
.icon-calendar:before { content: "\EA0D" }
.icon-car:before { content: "\EA0E" }
.icon-check:before { content: "\EA0F" }
.icon-checkmark:before { content: "\EA10" }
.icon-clock:before { content: "\EA11" }
.icon-close:before { content: "\EA12" }
.icon-contact_fax:before { content: "\EA13" }
.icon-contact_mail:before { content: "\EA14" }
.icon-contact_phone:before { content: "\EA15" }
.icon-cursor:before { content: "\EA16" }
.icon-delete:before { content: "\EA17" }
.icon-departure-new:before { content: "\EA18" }
.icon-departure:before { content: "\EA19" }
.icon-depature-round:before { content: "\EA1A" }
.icon-download:before { content: "\EA1B" }
.icon-elements:before { content: "\EA1C" }
.icon-eye:before { content: "\EA1D" }
.icon-facebook:before { content: "\EA1E" }
.icon-globe:before { content: "\EA1F" }
.icon-linkedin:before { content: "\EA20" }
.icon-mail:before { content: "\EA21" }
.icon-menu:before { content: "\EA22" }
.icon-pinterest:before { content: "\EA23" }
.icon-plane:before { content: "\EA24" }
.icon-planesearch:before { content: "\EA25" }
.icon-play:before { content: "\EA26" }
.icon-plus:before { content: "\EA27" }
.icon-print:before { content: "\EA28" }
.icon-quotation-mark:before { content: "\EA29" }
.icon-search:before { content: "\EA2A" }
.icon-service:before { content: "\EA2B" }
.icon-snowflake:before { content: "\EA2C" }
.icon-social_facebook:before { content: "\EA2D" }
.icon-social_instagram:before { content: "\EA2E" }
.icon-social_linkedin:before { content: "\EA2F" }
.icon-social_youtube:before { content: "\EA30" }
.icon-sun:before { content: "\EA31" }
.icon-switch:before { content: "\EA32" }
.icon-tag:before { content: "\EA33" }
.icon-teaser_bag:before { content: "\EA34" }
.icon-teaser_check-in:before { content: "\EA35" }
.icon-teaser_compass:before { content: "\EA36" }
.icon-teaser_flightschedule:before { content: "\EA37" }
.icon-teaser_flugverbot:before { content: "\EA38" }
.icon-teaser_food:before { content: "\EA39" }
.icon-teaser_law:before { content: "\EA3A" }
.icon-teaser_park:before { content: "\EA3B" }
.icon-teaser_tower:before { content: "\EA3C" }
.icon-temperature:before { content: "\EA3D" }
.icon-train:before { content: "\EA3E" }
.icon-twitter:before { content: "\EA3F" }
.icon-upload:before { content: "\EA40" }
.icon-weather:before { content: "\EA41" }
.icon-webcam:before { content: "\EA42" }
.icon-whatsapp:before { content: "\EA43" }


:root {
--icon-addthis: "\EA01";
    --icon-arrival-new: "\EA02";
    --icon-arrival-round: "\EA03";
    --icon-arrival: "\EA04";
    --icon-arrow-left: "\EA05";
    --icon-arrow-right: "\EA06";
    --icon-arrow_big-right: "\EA07";
    --icon-arrow_extern: "\EA08";
    --icon-arrow_round-right: "\EA09";
    --icon-arrow_small-down: "\EA0A";
    --icon-arrow_small-right: "\EA0B";
    --icon-bag: "\EA0C";
    --icon-calendar: "\EA0D";
    --icon-car: "\EA0E";
    --icon-check: "\EA0F";
    --icon-checkmark: "\EA10";
    --icon-clock: "\EA11";
    --icon-close: "\EA12";
    --icon-contact_fax: "\EA13";
    --icon-contact_mail: "\EA14";
    --icon-contact_phone: "\EA15";
    --icon-cursor: "\EA16";
    --icon-delete: "\EA17";
    --icon-departure-new: "\EA18";
    --icon-departure: "\EA19";
    --icon-depature-round: "\EA1A";
    --icon-download: "\EA1B";
    --icon-elements: "\EA1C";
    --icon-eye: "\EA1D";
    --icon-facebook: "\EA1E";
    --icon-globe: "\EA1F";
    --icon-linkedin: "\EA20";
    --icon-mail: "\EA21";
    --icon-menu: "\EA22";
    --icon-pinterest: "\EA23";
    --icon-plane: "\EA24";
    --icon-planesearch: "\EA25";
    --icon-play: "\EA26";
    --icon-plus: "\EA27";
    --icon-print: "\EA28";
    --icon-quotation-mark: "\EA29";
    --icon-search: "\EA2A";
    --icon-service: "\EA2B";
    --icon-snowflake: "\EA2C";
    --icon-social_facebook: "\EA2D";
    --icon-social_instagram: "\EA2E";
    --icon-social_linkedin: "\EA2F";
    --icon-social_youtube: "\EA30";
    --icon-sun: "\EA31";
    --icon-switch: "\EA32";
    --icon-tag: "\EA33";
    --icon-teaser_bag: "\EA34";
    --icon-teaser_check-in: "\EA35";
    --icon-teaser_compass: "\EA36";
    --icon-teaser_flightschedule: "\EA37";
    --icon-teaser_flugverbot: "\EA38";
    --icon-teaser_food: "\EA39";
    --icon-teaser_law: "\EA3A";
    --icon-teaser_park: "\EA3B";
    --icon-teaser_tower: "\EA3C";
    --icon-temperature: "\EA3D";
    --icon-train: "\EA3E";
    --icon-twitter: "\EA3F";
    --icon-upload: "\EA40";
    --icon-weather: "\EA41";
    --icon-webcam: "\EA42";
    --icon-whatsapp: "\EA43";
    
}