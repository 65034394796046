.contact-teaser{
    @media screen and (min-width: 768px){
        padding-bottom: calc(155rem / 16);
    }
}

.contact-teaser__box{
    box-shadow: 0 10px 20px 0 rgba(43,33,113,.1);
    padding: calc(16rem / 16);
    @media screen and (min-width: 768px) {
        padding: calc(60rem / 16) calc(50rem / 16) calc(50rem / 16);
    }
}

.contact-teaser__heading{
    @media screen and (min-width: 768px) {
        transform: translateY(calc(100rem / 16));
    }
}
.contact-teaser__content{
    @media screen and (max-width: 767px){
        margin-top: calc(12rem / 16);
    }
    @media screen and (min-width: 768px) {
        transform: translateY(calc(150rem / 16));
    }
}
.contact-teaser__contact{
    margin-top: calc(20rem / 16);
    margin-bottom: calc(20rem / 16);
}